<template>
  <div class="footer">
    <div class="service-intro">
      <div style="padding:20px;width:700px;">
        <div class="servece-type">
          <div class="servece-type-info">
            <ul>
              <li>企业服务</li>
              <li>职位搜索</li>
              <li>新闻资讯</li>
              <li>一浪APP</li>
            </ul>
          </div>
          <div class="servece-type-info">
            <ul>
              <li>使用与帮助</li>
              <li>隐私政策</li>
              <li>防骗指南</li>
              <li>使用帮助</li>
            </ul>
          </div>
          <div class="servece-type-info">
            <ul>
              <li>联系一浪</li>
              <li>广西一浪科技有限公司</li>
              <li>公司地址：广西南宁市西乡塘区北大路梦泽园1103室</li>
              <li>违法和不良信息举报邮箱</li>
            </ul>
          </div>
        </div>
        <div class="copyright">
          <p>Copyright © 2018-{{ year_now }} 广西一浪科技有限公司</p>
        </div>
        <div>
          备案号:<a href="https://beian.miit.gov.cn">桂ICP备2022004865号-1</a>
        </div>
      </div>
      <div class="right" style="padding: 20px;">
        <div class="righttext"
             style="margin-top: 25px;font-style: oblique;color: #333333;font-weight: bolder;font-size: 16px;">一浪科技
        </div>
        <div class="righttext">企业服务热线</div>
        <div class="righttext">老年人直连热线</div>
        <div class="righttext">工作日 8:00-22:00</div>
        <div class="righttext">休息日 9:30-18:00</div>
        <div style="display: flex;justify-content: space-between">
          <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
          >
            <div>
              <img src="@/assets/images/wechatqrcode.jpg">
            </div>
            <div slot="reference" class="mp_popover_class"></div>
          </el-popover>

          <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
          >
            <div>
              <img slot="reference" src="@/assets/images/wechatqrcode.jpg">
            </div>
            <div slot="reference" class="wechat_popover_class"></div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year_now: '',
    };
  },
  mounted() {
    var date = new Date();
    this.year_now = date.getFullYear();
  },
  methods: {}
};
</script>

<style scoped lang="scss">
/deep/ .mp_popover_class {
  width: 50px;
  height: 50px;
  background: url("@/assets/images/mp.png") no-repeat;

  &:hover {
    background: url("@/assets/images/mp_light.png") no-repeat;
  }
}

/deep/ .wechat_popover_class {
  width: 50px;
  height: 50px;
  background: url("@/assets/images/wechat.png") no-repeat;

  &:hover {
    background: url("@/assets/images/wechat_light.png") no-repeat;
  }
}

/*****************************底 部 开 始*****************************/
.footer {
  width: 100%;
  height: 390px;
  background-color: #ffffff;
  border-top: 1px solid #e2e2e2;
}

.service-intro {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.servece-type {
  margin: 15px auto;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.servece-type-info {
  color: #666666;
}

.servece-type-info ul {
  list-style: none;
}

.servece-type-info li {
  font-size: 14px;
  cursor: pointer;
  line-height: 35px;

  &:hover {
    color: $primary_color;
  }
}

.servece-type-info li:first-child {
  font-size: 16px;
  line-height: 50px;
  font-weight: bold;
  color: #333333;
}

.friend-link-item ul {
  list-style: none;
}

.friend-link-item li {
  padding: 15px 0px;
  float: left;
}

.copyright {
  width: 100%;
  color: #666;
  line-height: 30px;
  text-align: left;
}

.copyright a {
  color: #232323;
  font-size: 20px;
}

.right {
  .righttext {
    text-align: right;
    margin-bottom: 15px;
  }
}


/*****************************底 部 结 束*****************************/
</style>
